.entry-content>* {
    margin-bottom: 0 !important;
}

a {
    text-decoration: none !important;
}

#header {
    [data-row*=top] {
        position: relative;
        &:before {
            content: '';
            width: 95px;
            height: 100%;
            background: url(/wp-content/uploads/2022/12/balk.png);
            display: block;
            background-position: center center;
            background-size: auto;
            background-repeat: no-repeat;
            position: absolute;
            left: 30px;
        }
    }
}

span.ct-toggle-dropdown-desktop {
    margin-bottom: -5px;
    margin-left: 7px;
    svg {
        --icon-size: 14px;
        fill: #EB6023 !important;
    }
}

.ct-search-box {
    margin-right: -15px !important;
    form.search-form {
        input {
            padding: 0 10px 0 40px;
            font-family: DM Sans, sans-serif !important;
            font-weight: 700;
            font-size: 20px;
        }
        button {
            left: 0;
            right: auto;
            svg {
                width: auto !important;
                height: auto !important;
            }
        }
    }
}

.b-header {
    padding: 0 !important;
    font-family: DM Sans, sans-serif !important;
    min-height: auto !important;
    span.ct-icon-container {
        margin-right: 10px;
        svg {
            width: auto !important;
            height: auto !important;
        }
    }
}

.custom-button {
    a {
        height: 111px;
        clip-path: polygon(0% 0, 105% 0, 90% 100%, 0 100%, 0 20%);
        -webkit-clip-path: polygon(0% 0, 105% 0, 90% 100%, 0 100%, 0 20%);
        justify-content: left !important;
        padding: 5px 33px !important;
    }
}

.custom-b {
    a.stk-link{
        clip-path: polygon(0% 0, 107% 0, 91% 100%, 0 100%, 0 20%);
        -webkit-clip-path: polygon(0% 0, 107% 0, 91% 100%, 0 100%, 0 20%);
        &:hover {
            background-color: var(--paletteColor1, #182D4C);
        }
    }
}

.style-img {
    img {
        width: 100%;
    }
}

.custom-b-border {
    .stk-row {
        column-gap: 0 !important;
        .wp-block-stackable-button {
            flex: initial !important;
        }
    }
    a.stk-link {
        justify-content: space-between !important;
        position: relative !important;
        width: 210px;
        &:before {
            border-width: 0 !important;
            content: '';
            display: block;
            width: 100% !important;
            height: 100% !important;
            background: url(/wp-content/uploads/2022/12/Subtraction-62.png);
            background-position: center center;
            background-size: contain;
            background-repeat: no-repeat;
            opacity: 1 !important;
        }
    }
}

.bg-style {
    &:before {
        height: 972px;
    }
}

.bg-fleet-style {
    &:before {
        height: 486px;
    }
}

.c-style {
    clip-path: polygon(0% 0%, 100% 0, 100% 92%, 97% 100%, 0% 100%);
    -webkit-clip-path: polygon(0% 0%, 100% 0, 100% 92%, 97% 100%, 0% 100%);
}

.media-style {
    clip-path: polygon(0% 0%, 100% 0, 100% 79%, 97% 100%, 0% 100%);
    -webkit-clip-path: polygon(0% 0%, 100% 0, 100% 79%, 97% 100%, 0% 100%);
    .wp-block-media-text__content {
        padding: 46px 80px 22px 65px !important;
        h2 {
            margin-bottom: 15px !important;
        }
    }
}

.media-s-style {
    clip-path: polygon(0% 0%, 100% 0, 100% 79%, 95% 100%, 0% 100%);
    -webkit-clip-path: polygon(0% 0%, 100% 0, 100% 79%, 95% 100%, 0% 100%);
    figure.wp-block-media-text__media {
        padding-left: 40px;
    }
    .wp-block-media-text__content {
        padding: 45px 35px 82px 41px !important;
        h4 {
            margin-bottom: 12px !important;
        }
        p {
            font-size: 18px;
            line-height: 32px;
        }
    }
}

.p-single-style {
    line-height: 32px;
}

.fleet-single-css {
    figure.wp-block-media-text__media {
        padding-left: 15px;
    }
    .wp-block-media-text__content {
        padding: 46px 80px 22px 138px !important;
        .gb-grid-wrapper {
            padding-right: 120px;
            h3 {
                margin-bottom: 0 !important;
            }
        }
    }
}

.ct-footer {
    [data-row*="middle"] {
        position: relative;
        &:before {
            content: '';
            width: 100%;
            height: 100%;
            background: url(/wp-content/uploads/2022/12/Mask-Group-3.png);
            display: block;
            background-position: center center;
            background-size: cover;
            background-repeat: no-repeat;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }
    }
}

[data-column="widget-area-2"] {
    #block-5 {
        margin-bottom: 20px !important;
    }
    .ct-widget {
        margin: 0 !important;
    }
}

.projects-home {
    .entries {
        article {
            clip-path: polygon(0 0, 100% 0, 100% 88%, 0 240%, 0 20%);
            -webkit-clip-path: polygon(0 0, 100% 0, 100% 88%, 0 240%, 0 20%);
            padding: 65px 65px 35px 65px !important;
            h2.entry-title {
                margin-top: 25px !important;
            }
            .entry-button {
                position: relative;
                font-family: Cairo, Sans-Serif;
                font-weight: 700;
                font-size: 20px;
                line-height: 37px;
                &:after {
                    content: '';
                    display: block;
                    width: 18px;
                    height: 18px;
                    background: url(/wp-content/uploads/2022/12/icon.png);
                    background-position: center center;
                    background-size: auto;
                    background-repeat: no-repeat;
                    position: absolute;
                    top: 10px;
                    right: -40px;
                    bottom: 0;
                }
            }
        }
    }
}

.style-gallery {
    figure.wp-block-image {
        a {
            position: relative;
            &:before {
                content: '';
                width: 100%;
                height: 100%;
                display: block;
                background-color: rgba(0, 45, 75, 0.8);
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                opacity: 0;
                -webkit-transition: all 100ms;
                transition: all 100ms;
            }
            &:after {
                content: '';
                display: block;
                background: url(/wp-content/uploads/2022/12/magnifier.png);
                background-position: center center;
                background-size: auto;
                background-repeat: no-repeat;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                opacity: 0;
                -webkit-transition: all 100ms;
                transition: all 100ms;
            }
            &:hover {
                &:before {
                    opacity: 1;
                    -webkit-transition: all 1s;
                    transition: all 1s;
                }
                &:after {
                    opacity: 1;
                    -webkit-transition: all 1s;
                    transition: all 1s;
                }
            }
        }
    }
}

.facetwp-facet {
    display: inline-block;
    .facetwp-dropdown {
        width: auto;
        height: 54px;
        background-color: #eb6023;
        border: 0;
        border-radius: 0;
        clip-path: polygon(0% 0, 107% 0, 91% 100%, 0 100%, 0 20%);
        -webkit-clip-path: polygon(0% 0, 107% 0, 91% 100%, 0 100%, 0 20%);
        font-weight: 700;
        font-size: 20px;
        color: #FFFFFF;
        padding: 0 30px;
        padding: 0 50px 0 30px;
        background-size: 13px auto, 100% !important;
        background-position: right 30px top 52%, 6px 7px !important;
        background-image: url("data:image/svg+xml,%3Csvg width='21' height='13' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18.5.379L20.621 2.5 10.5 12.621.379 2.5 2.5.379l8 8z' fill='white' fill-rule='nonzero'/%3E%3C/svg%3E");
        option {
            color: #FFFFFF;
        }
    }
}

.facetwp-template[data-name="fleet_template"] {
    .fwpl-layout {
        .fwpl-result {
            .fwpl-col {
                position: relative;
                &:before {
                    content: '';
                    width: 100%;
                    height: 100%;
                    display: block;
                    background-color: rgba(0, 45, 75, 0.8);
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    opacity: 0;
                    -webkit-transition: all 100ms;
                    transition: all 100ms;
                }
                &:after {
                    content: '';
                    display: block;
                    height: 294px;
                    background: url(/wp-content/uploads/2022/12/kompas-braveheart.png);
                    background-position: center center;
                    background-size: auto;
                    background-repeat: no-repeat;
                    position: absolute;
                    top: 75px;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    opacity: 0;
                    -webkit-transition: all 100ms;
                    transition: all 100ms;
                }
                .post-title {
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: 65px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    opacity: 0;
                    -webkit-transition: all 100ms;
                    transition: all 100ms;
                    font-family: Cairo, Sans-Serif;
                    font-weight: 700;
                    font-size: 40px;
                    line-height: 75px;
                    color: #FFFFFF;
                }
                .show-more-link {
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    height: 100%;
                    width: 100%;
                    opacity: 0;
                    -webkit-transition: all 100ms;
                    transition: all 100ms;
                    a {
                        cursor: pointer;
                        display: block;
                        height: 100%;
                        width: 100%;
                        button {
                            cursor: pointer;
                            background-color: transparent;
                            font-family: Cairo, Sans-Serif;
                            font-weight: 700;
                            font-size: 20px;
                            line-height: 37px;
                            color: #EB6023;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            position: absolute;
                            bottom: 40px;
                            left: 0;
                            right: 0;
                        }
                    }
                }
                &:hover {
                    &:before {
                        opacity: 1;
                        -webkit-transition: all 1s;
                        transition: all 1s;
                    }
                    &:after {
                        opacity: 1;
                        -webkit-transition: all 1s;
                        transition: all 1s;
                    }
                    .post-title {
                        opacity: 1;
                        -webkit-transition: all 1s;
                        transition: all 1s;
                    }
                    .show-more-link {
                        opacity: 1;
                        -webkit-transition: all 1s;
                        transition: all 1s;
                    }
                }
            }
        }
    }
}

.facetwp-template[data-name="project_template"] {
    .fwpl-layout {
        grid-gap: 48px;
        .fwpl-result {
            .fwpl-col {
                .bg-project {
                    a {
                        display: block;
                        height: 377px;
                        img {
                            height: 100%;
                            width: 100%;
                            object-fit: cover;
                        }
                    }
                }
                .title-project{
                    line-height: 60px;
                }
                .ex-project {

                }
                .link-project {
                    a {
                        button {
                            background-color: transparent;
                            font-family: Cairo, Sans-Serif;
                            cursor: pointer;
                            position: relative;
                            &:after {
                                content: '';
                                display: block;
                                width: 19px;
                                height: 19px;
                                background: url(/wp-content/uploads/2022/12/icon.png);
                                background-position: center center;
                                background-size: auto;
                                background-repeat: no-repeat;
                                position: absolute;
                                top: 10px;
                                right: -40px;
                                bottom: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

.custom-grid-line {
    position: relative;
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-bottom: 2px solid #182D4C;
        margin-left: 35px;
    }
    .num-style {
        .stk-block-number-box__text {
            &:before {
                content: '';
                position: absolute;
                bottom: -23px;
                width: 2px;
                height: 19px;
                background-color: #182D4C;
            }
        }
    }
}

article>.entry-content>*.alignfull {
    .gb-inside-container {
        width: var(--default-editor, var(--block-width));
    }
}

@include break-below(lg) {
    .bg-cover-mobile {
        min-height: 500px !important;
    }
}

@include break-below(lgt) {
    .facetwp-template[data-name="project_template"] {
        .fwpl-layout{
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 30px;
        }
    }
    .facetwp-template[data-name="project_template"] {
        .fwpl-layout{
            .fwpl-result {
                .fwpl-col {
                    .bg-project {
                        a {
                            height: 250px;
                        }
                    }
                }
            }
        }
    }
    .fleet-single-css {
        .wp-block-media-text__content {
            .gb-grid-wrapper {
                padding-right: 0;
                .gb-grid-column {
                    width: 50%;
                }
            }
        }
    }
    .facetwp-template[data-name="fleet_template"] {
        .fwpl-layout {
            .fwpl-result {
                .fwpl-col {
                    &:after {
                        height: 200px;
                        background-size: contain;
                        top: 20px;
                    }
                }
            }
        }
    }
}

@include break-below(sm) {
    p.gb-headline {
        line-height: 30px !important;
    }
    .bg-cover-mobile {
        min-height: 223px !important;
    }
    .custom-button a {
        height: 67px;
        font-size: 25px !important;
    }
    .projects-home {
        .entries  {
            article {
                padding: 35px !important;
                h2.entry-title {
                    margin-top: 0 !important;
                    margin-bottom: 10px !important;
                }
            }
        }
    }
    body {
        .facetwp-template {
            .fwpl-layout {
                grid-template-columns: 1fr;
                grid-gap: 25px;
            }
        }
    }
    .facetwp-template[data-name="project_template"] {
        .fwpl-layout{
            grid-template-columns: 1fr;
            grid-gap: 25px;
        }
    }
    .facetwp-grid-style {
        .facetwp-facet {
            width: 100%;
            .facetwp-dropdown {
                width: 100%;
                option {
                    width: auto;
                }
            }
        }
    }
    .custom-grid-line {
        &:before {
            display: none !important;
        }
        .num-style {
            .stk-block-number-box__text {
                &:before {
                    display: none !important;
                }
            }
        }
    }
    .media-style {
        grid-template-columns: 100% !important;
        clip-path: polygon(0% 0%, 100% 0, 100% 90%, 90% 100%, 0% 100%);
        -webkit-clip-path: polygon(0% 0%, 100% 0, 100% 90%, 90% 100%, 0% 100%);
        .wp-block-media-text__media {
            grid-column: 1 !important;
            grid-row: 1 !important;
            padding-left: 0 !important;
        }
        .wp-block-media-text__content {
            grid-column: 1 !important;
            grid-row: 2 !important;
            padding: 30px !important;
        }
    }
    .media-s-style {
        grid-template-columns: 100% !important;
        clip-path: polygon(0% 0%, 100% 0, 100% 90%, 90% 100%, 0% 100%);
        -webkit-clip-path: polygon(0% 0%, 100% 0, 100% 90%, 90% 100%, 0% 100%);
        .wp-block-media-text__media {
            grid-column: 1 !important;
            grid-row: 1 !important;
            padding-left: 0 !important;
            padding-top: 30px;
        }
        .wp-block-media-text__content {
            grid-column: 1 !important;
            grid-row: 2 !important;
            padding: 30px !important;
        }
    }
}

@include break-below(bt) {
    .home {
        .custom-b-border {
            .stk-row {
                column-gap: 12px !important;
                .wp-block-stackable-button {
                    
                }
            }
            a.stk-link {
                width: 175px !important;
                padding: 6px 15px !important;
                .stk-button__inner-text {
                    font-size: 16px !important;
                }
                .stk--svg-wrapper {
                    .stk--inner-svg {
                        svg {
                            font-size: 15px !important;
                        }
                    }
                }
            }
        }
    }
}

@include break-below(xs) {
    
}